<template>
  <div>
    <app-menu/> 
    <v-container class="mx-auto text-center" style="min-height:706px;">
      <br><br><br>
      <!-- width="280" -->
      <!-- <img width="80%" src="http://refividrio.com.mx/ecommerce/mantenimiento12.png"> -->
      <v-img :src="`${ubicacionImagen}mantenimiento12.png`" width="80%">  </v-img>
      
      <p style="font-size: 2em;color :#909090">  
        <strong>La página que buscas aún no esta disponible.</strong> 
      </p>
    </v-container>
  </div>
</template> 
<script> 
import AppMenu from '../components/Menu.vue';

export default { 
  data() {
    return {
      ubicacionImagen:'https://amoresens.com.mx/distribuidor/generales/',

    }; 
  }, 
  components: {  
    'app-menu': AppMenu, 
  }
}
</script> 
